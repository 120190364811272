import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
@Injectable({
    providedIn: 'root'
})
export class DataEncryptionDcryption {

    // encryptData(plainText: any) {
    //     console.log('plainText',plainText)
    //     const key = CryptoJS.enc.Utf8.parse('12345678901234567890123456789012');
    //     const iv = CryptoJS.enc.Utf8.parse('1234567890123456');
    //     const encrypted = CryptoJS.AES.encrypt(plainText,key,{ iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    //     //console.log('encrypted====',encrypted.toString())
        
    //     return encodeURIComponent(encrypted.toString());
    //   }
    private generateHash(data: string): string {
      return CryptoJS.SHA256(data).toString(CryptoJS.enc.Base64);
    }
  
    encryptDataPass(plainText: string) {
       // Generate a random IV
       const iv = CryptoJS.lib.WordArray.random(16);
        
       const key = CryptoJS.enc.Utf8.parse('12345678901234567890123456789012');
       const encrypted = CryptoJS.AES.encrypt(plainText, key, {
           iv: iv,
           mode: CryptoJS.mode.CBC,
           padding: CryptoJS.pad.Pkcs7
       });
       
       // Combine IV and encrypted data
       const encryptedDataWithIV = iv.concat(encrypted.ciphertext);
       // Base64 URL-safe encoding
       const base64String = CryptoJS.enc.Base64.stringify(encryptedDataWithIV);

       // Base64 URL-safe encoding
       const base64UrlString = base64String
           .replace(/\+/g, '-')  // Replace + with -
           .replace(/\//g, '_')  // Replace / with _
           .replace(/=+$/, '');  // Remove trailing =
   
       console.log('encoded', encodeURIComponent(base64UrlString));
       //return encodeURIComponent(CryptoJS.enc.Base64.stringify(encryptedDataWithIV));
       return encodeURIComponent(base64UrlString);
  }
      // Generate hash
  getIV(): CryptoJS.lib.WordArray {
    // Retrieve the IV from session storage
    const ivBase64 = sessionStorage.getItem('encryption_iv');
    if (ivBase64) {
      // Decode the IV from Base64 format
      return CryptoJS.enc.Base64.parse(ivBase64);
    }
    throw new Error('IV not found in session storage');
  }
      encryptData(plainText: any) {
        console.log('plainText', plainText);
        
        // Generate a random IV
        const iv = CryptoJS.lib.WordArray.random(16);
        
        const key = CryptoJS.enc.Utf8.parse('12345678901234567890123456789012');
        const encrypted = CryptoJS.AES.encrypt(plainText, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        
        // Combine IV and encrypted data
        const encryptedDataWithIV = iv.concat(encrypted.ciphertext);
        // Base64 URL-safe encoding
        const base64String = CryptoJS.enc.Base64.stringify(encryptedDataWithIV);

        // Base64 URL-safe encoding
        const base64UrlString = base64String
            .replace(/\+/g, '-')  // Replace + with -
            .replace(/\//g, '_')  // Replace / with _
            .replace(/=+$/, '');  // Remove trailing =
    
        console.log('encoded', encodeURIComponent(base64UrlString));
        //return encodeURIComponent(CryptoJS.enc.Base64.stringify(encryptedDataWithIV));
        return encodeURIComponent(base64UrlString);
    }
   
  //   encryptData(plainText: string): string {
  //     console.log('plainText', plainText);
      
  //     // Generate a random IV
  //     const iv = CryptoJS.lib.WordArray.random(16);
      
  //     // Define the key (32 bytes for AES-256)
  //     const key = CryptoJS.enc.Utf8.parse('12345678901234567890123456789012');
      
  //     // Encrypt the data
  //     const encrypted = CryptoJS.AES.encrypt(plainText, key, {
  //         iv: iv,
  //         mode: CryptoJS.mode.CBC,
  //         padding: CryptoJS.pad.Pkcs7
  //     });
      
  //     // Combine IV and encrypted data
  //     const encryptedDataWithIV = iv.concat(encrypted.ciphertext);
      
  //     // Encode as Base64 and make it URL-safe
  //     let base64Encoded = CryptoJS.enc.Base64.stringify(encryptedDataWithIV);
  //     base64Encoded = base64Encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''); // URL-safe Base64
  
  //     return base64Encoded;
  // }
      encryptData2(plainText: any) {
        console.log('plainText',plainText)
        const key = CryptoJS.enc.Utf8.parse('12345678901234567890123456789012');
        const iv = CryptoJS.enc.Utf8.parse('1234567890123456');
        const encrypted = CryptoJS.AES.encrypt(plainText,key,{ iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        //console.log('encrypted====',encrypted.toString())
        
        return encrypted.toString();
      }
      decryptData(encryptedData: any) {
        const key = CryptoJS.enc.Utf8.parse('12345678901234567890123456789012');
        const iv = CryptoJS.enc.Utf8.parse('1234567890123456');
        const decryptedText = CryptoJS.AES.decrypt(encryptedData, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });
        // console.log('decryptedText',decryptedText)
        // console.log('decryptedText====',decryptedText.toString(CryptoJS.enc.Utf8))
        return decryptedText.toString(CryptoJS.enc.Utf8);
    }
      decryptString(encryptedData: any) {
      //console.log('encryptedData',encryptedData)
      const key = '12345678901234567890123456789012';
      const keyBytes = CryptoJS.enc.Utf8.parse(key);
      const combinedData = CryptoJS.enc.Base64.parse(encryptedData);
      const iv = combinedData.clone();
      iv.sigBytes = 16;
      iv.clamp();
    
      const encryptedText = combinedData.clone();
      encryptedText.words.splice(0, 4); // remove IV
      encryptedText.sigBytes -= 16;
       // Construct CipherParams object with ciphertext and IV
       const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: encryptedText
      });
    
      // Decryption
      const decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        keyBytes,
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }
      );
       console.log('decrypted',JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)))
      // Convert WordArray to Utf8 string
      return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)) ;
      // return encryptedData ;
      
    }
}
