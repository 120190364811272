import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'pt', loadChildren: () => import('./member/member.module').then(m => m.MemberModule)  },
  { path: 'dr', loadChildren: () => import('./doctor/doctor-main-site.module').then(m => m.DoctorMainSiteModule)  },
  { path: 'nd', loadChildren: () => import('./doctor/doctor-main-site.module').then(m => m.DoctorMainSiteModule)  },
  { path: 'cc', loadChildren: () => import('./Clinic-Coodinator/cc.module').then(m => m.CCModule)  },
  { path: 'ccnd', loadChildren: () => import('./Clinic-Coodinator/cc.module').then(m => m.CCModule)  },
  { path: 'institute', loadChildren: () => import('./institute-admin/institute-admin.module').then(m => m.InstituteAdminModule)  },
  { path: 'institute-assistent', loadChildren: () => import('./institute-assistent/institute-assistent.module').then(m => m.InstituteAssistentModule)  },
  { path: 'la', loadChildren: () => import('./lab-admin/lab-admin.module').then(m => m.LabAdminModule)  },
  { path: 'lab', loadChildren: () => import('./lab-admin/lab-admin.module').then(m => m.LabAdminModule)  },
  { path: 'pa', loadChildren: () => import('./Pharmacy-admin/pharmacy-admin.module').then(m => m.PharmacyAdminModule)  },
  { path: 'pharmacy', loadChildren: () => import('./Pharmacy-admin/pharmacy-admin.module').then(m => m.PharmacyAdminModule)  },
  { path: 'corporate', loadChildren: () => import('./corporate-admin/corporate-admin.module').then(m => m.CorporateAdminModule)  },
  { path: 'ad', loadChildren: () => import('./admin/ad.module').then(m => m.AdModule)  },
  { path: 'sad', loadChildren: () => import('./superadmin/sad.module').then(m => m.SAdModule)  },
  { path: 'ab', loadChildren: () => import('./AmbulanceService/ambulanceService.module').then(m => m.AmbulanceServiceModule)  },
  { path: 'entity', loadChildren: () => import('./entity-admin/entity-admin.module').then(m => m.EntityAdminModule)  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientDashboardRoutingModule { }
