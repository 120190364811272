import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataEncryptionDcryption } from 'src/app/core/services/dataEncryptionDcryption.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AdhocAppointmentService {

    constructor(
        private http: HttpClient,
        private dataEncryptionDcryption: DataEncryptionDcryption
    
      ) {
    
      }
    
    apiUrl = environment.dromeApiUrl;

    getPatientDetailsLikeSearch(vGeneric: string): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/PatientMaster/GetPatientDetailsLikeSearch/${vGeneric}`);

    } 
    GetDoctorDetailsLikeSearchClinic(nClinicId,vGeneric): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/EncounterDetails/GetDoctorDetailsLikeSearchClinic/${nClinicId}/${vGeneric}`);

    }
    GetPatientDetailsForInstitutesLikeSearch(nLoggedInUserId, vGeneric: string): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/PatientMaster/GetPatientDetailsForInstitutesLikeSearch/${this.dataEncryptionDcryption.encryptData(nLoggedInUserId.toString())}/${vGeneric}`);

    }
    GetPlanForMembers_InstituteAppointment(nPatientUserId, AppDate): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/EncounterDetails/GetPlanForMembers_InstituteAppointment/${this.dataEncryptionDcryption.encryptData(nPatientUserId.toString())}/${AppDate}`);
    }
    GetPatientDetailsLikeSearchNonDrome(nLoggedInUserId, vGeneric: string): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/PatientMaster/GetPatientDetailsLikeSearchNonDrome/${nLoggedInUserId}/${vGeneric}`);

    }
    getDoctorDetailsForCC(nCCUserId: number): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/EncounterDetails/GetDoctorDetailsForCC/${this.dataEncryptionDcryption.encryptData(nCCUserId.toString())}`);
    }
    GetDoctorDetailsForCCNonClinic(nCCUserId: number): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/EncounterDetails/GetDoctorDetailsForCCNonClinic/${nCCUserId}`);
    }
    GetDoctorDetailsForCCLetterHead(nCCUserId: number): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/EncounterDetails/GetDoctorDetailsForCCLetterHead/${nCCUserId}`);
    }
    GetDoctorDetailsForInstituteLikeSearch(nLoggedInUserId, vGeneric): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/EncounterDetails/GetDoctorDetailsForInstituteLikeSearch/${this.dataEncryptionDcryption.encryptData(nLoggedInUserId.toString())}/${vGeneric}`);
    }
    GetvEncounterPrefix(nLoggedInUserId): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/EncounterDetails/GetvEncounterPrefix/${this.dataEncryptionDcryption.encryptData(nLoggedInUserId.toString())}`);
    }
    InstituteMemberId_Update(data): Observable<any> {
        return this.http.put<any>(`${this.apiUrl}/PatientMaster/InstituteMemberId_Update`, data);
    } 
    EncounterDetails_VideoCall_Insert(data): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/VideoCall/EncounterDetails_VideoCall_Insert`, data);
    }
}

