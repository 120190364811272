import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class IsSignedInGuard implements CanActivate {
    constructor(
        private storageService: StorageService,
        private router: Router
    ) { }
    
    // canActivate(): boolean {
    //     if (this.storageService.isUserLogedIn) {
    //         // this.router.navigate(["/dashboard"]);
    //         if (this.storageService.roleId == "1") {
    //             this.router.navigate(['/dashboard/pt/doctor-list'], {
    //             });
    //           } else if (this.storageService.roleId == "2") {
    //             //dr/pending-consultations
    //             this.router.navigate(['/dashboard/dr/taskscalendarview'], {
    //             });
    //           }
    //            else if (this.storageService.roleId == "3") {
    //             //dr/pending-consultations
    //             this.router.navigate(['/dashboard/pharmacy/orderdetails'], {
    //             });
    //           }
    //           else if (this.storageService.roleId == "4") {
    //             //dr/pending-consultations
    //             this.router.navigate(['/dashboard/ad/view-profile'], {
    //             });
    //           }
    //           else if (this.storageService.roleId == "5") {
    //             //dr/pending-consultations
    //             this.router.navigate(['/dashboard/sad/take-action-on-unscbscibe'], {
    //             });
    //           }
    //           else if (this.storageService.roleId == "6") {
    //             //dr/pending-consultations
    //             this.router.navigate(['/dashboard/cc/adhoc-appointment'], {
    //             });
    //           }
    //           else if (this.storageService.roleId == "8") {
    //             //dr/pending-consultations
    //             this.router.navigate(['/dashboard/nd/taskscalendarview'], {
    //             });
    //           }
    //           else if (this.storageService.roleId == "9") {
    //             //dr/pending-consultations
    //             this.router.navigate(['/dashboard/ccnd/view-profile'], {
    //             });
    //           } 
    //           else if (this.storageService.roleId == "11") {
    //             //dr/pending-consultations
    //             this.router.navigate(['/dashboard/lab/orderdetails'], {
    //             });
    //           } 
    //           else if (this.storageService.roleId == "12") {
    //             //dr/pending-consultations
    //             this.router.navigate(['/dashboard/pa/orderdetails'], {
    //             });
    //           } 
    //           else if (this.storageService.roleId == "13") {
    //             //dr/pending-consultations
    //             this.router.navigate(['/dashboard/la/orderdetails'], {
    //             });
    //           } 
    //           else if (this.storageService.roleId == "14") {
    //             //dr/pending-consultations
    //             this.router.navigate(['/dashboard/entity/view-profile'], {
    //             });
    //           }
    //           else if (this.storageService.roleId == "15") {
    //             //dr/pending-consultations
    //             this.router.navigate(['/dashboard/corporate/view-profile'], {
    //             });
    //           }
    //           else if (this.storageService.roleId == "16") {
    //             //dr/pending-consultations
    //             this.router.navigate(['/dashboard/institute/view-profile'], {
    //             });
    //           }
    //           else if (this.storageService.roleId == "17") {
    //             //dr/pending-consultations
    //             this.router.navigate(['/dashboard/institute/view-profile'], {
    //             });
    //           }
    //           else {
    //             this.router.navigate(['/dashboard'], {
    //             });
    //           }
    //         return false;
    //     }

    //     return true;

    // }
    canActivate(): boolean {

      if (this.storageService.isUserLogedIn) {
        switch (this.storageService.roleId) {
          case "1":
            this.router.navigate(['/dashboard/pt/doctor-list']);
            break;
          case "2":
            this.router.navigate(['/dashboard/dr/taskscalendarview']);
            break;
          case "3":
            this.router.navigate(['/dashboard/pharmacy/orderdetails']);
            break;
          case "4":
            this.router.navigate(['/dashboard/ad/view-profile']);
            break;
          case "5":
            this.router.navigate(['/dashboard/sad/take-action-on-unscbscibe']);
            break;
          case "6":
            this.router.navigate(['/dashboard/cc/adhoc-appointment']);
            break;
          case "8":
            this.router.navigate(['/dashboard/nd/taskscalendarview']);
            break;
          case "9":
            this.router.navigate(['/dashboard/ccnd/view-profile']);
            break;
          case "11":
            this.router.navigate(['/dashboard/lab/orderdetails']);
            break;
          case "12":
            this.router.navigate(['/dashboard/pa/orderdetails']);
            break;
          case "13":
            this.router.navigate(['/dashboard/la/orderdetails']);
            break;
          case "14":
            this.router.navigate(['/dashboard/entity/view-profile']);
            break;
          case "15":
            this.router.navigate(['/dashboard/corporate/view-profile']);
            break;
          case "16":
            this.router.navigate(['/dashboard/institute/view-profile']);
            break;
          case "17":
            this.router.navigate(['/dashboard/institute-assistent/view-profile']);
            break;
          default:
            this.router.navigate(['/dashboard']);
            break;
        }
        return false;
      }
      return true;
    }
    
}
