export const environment = {
  production: true,

  // dromeApiUrl: 'https://test-api.drome.health/api',
  // dromeImageUrl: 'https://test-api.drome.health',

  dromeApiUrl: 'https://dromeapi.azurewebsites.net/api',
  dromeImageUrl: 'https://dromeapi.azurewebsites.net',
 
  razarpayKey: 'rzp_live_hu7diPeXyxfHbc',
  agoraAppId: "3a36aa8875a74ceeb28a40f4fef90328",
  agoraCertificate: "13cdf66050824b62ba3e2ddb1c310b45",
  agoraToken: "",
  firebase: {
    apiKey: 'AIzaSyD9JHs-7mepxNL_is1LMkpchx1hSEVQ01U',
    authDomain: 'demoproject-139906.firebaseapp.com',
    projectId: 'demoproject-139906',
    storageBucket: 'demoproject-139906.appspot.com',
    messagingSenderId: '445073605770',
    appId: '1:445073605770:web:655957b2a4dfd72b349973',
    vapidKey: 'BAWRpP4tCQk4QnfolsZEsLxTHVq63ysim4PURJTNzj9HFwFf1hKiZXuObcmxdsbvUQ6G_sAO9SKeuxzuj9n2ArU'
  },
};
